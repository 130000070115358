import React from "react";
import { css } from "@emotion/react";
import { Stat } from "./Thumbnail";

export default class VideoPopup extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			video: false,
			data: false,
		}
	}

	popupListener(e) {
		this.setState({ video: e.detail.id.videoId, data: e.detail });
	}
	closeListener(e) {
		if (e.target.dataset.closepopup) {
			console.log('closing popup');
			this.setState({ video: null });
		}
	}
	componentDidMount() {
		window.addEventListener("popupvideo", this.popupListener.bind(this));
		window.addEventListener("click", this.closeListener.bind(this));
	}
	componentWillUnmount() {
		window.removeEventListener("popupvideo", this.popupListener.bind(this));
		window.removeEventListener("click", this.closeListener.bind(this));
	}

	render() {
		let stats;
		try {
			stats = this.state.data.statistics;
		} catch (e) { }

		return (<div
			css={css`
				background: rgba(0,0,0,0.5);
				backdrop-filter: blur(10px);
			`}
			data-closepopup={true}
			className={"fixed top-0 left-0 bottom-0 right-0 z-10 justify-center items-center " + (this.state.video ? "flex" : "hidden")}>

			{this.state.video ? <div css={css`width: 720px; max-width: 90%;`} className="bg-black rounded-xl overflow-hidden">
				<div css={css`
					padding: 28.125% 50%;
					width: 100%;
					box-sizing: border-box;
					position: relative;
				`}>
					<iframe
						className="absolute inset-0 w-full h-full"
						title="video"
						src={"https://www.youtube-nocookie.com/embed/" + this.state.video}
						frameBorder="0"
						allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen />
				</div>

				<div className="p-4">
					<div className="font-bold">{this.state.data.snippet.title}</div>
					<div className="text-sm my-1">{this.state.data.snippet.channelTitle}</div>
					{stats ? <div>
						{stats.commentCount ? <Stat icon="chat" value={stats.commentCount} /> : <></>}
						{stats.likeCount ? <Stat icon="thumb_up" value={stats.likeCount} /> : <></>}
						{stats.viewCount ? <Stat icon="visibility" value={stats.viewCount} /> : <></>}
					</div> : <></>}
				</div>
			</div> : null
			}
		</div>);
	}
}