import React from "react";
import Feed from "../components/Feed";

class Shorts extends React.Component {
	render() {
		return (<div>
			<div>
				<h1 className="text-center text-xl my-4">Shorts</h1>
			</div>
			<Feed feed="shorts" />
		</div>);
	}
}

export default Shorts;