import React from "react";
import moment from 'moment';

export const Stat = ({ icon = "visibility", value = 0 }) => {
	return (
		<div className="text-sm">
			<span className="material-icons text-sm">
				{icon}
			</span>
			<span className="inline-block align-top ml-1">{Number(value).toLocaleString()}</span>
		</div >
	);
}

const tHelper = (text) => { // add leading zeroes
	text = String(text);
	while (text.length < 2) {
		text = "0" + text;
	}
	return text;
}

class Thumbnail extends React.Component {

	render() {
		let thumbnails = this.props.data.snippet.thumbnails;
		//convert thumbnails object to array
		let thumbnailArray = Object.keys(thumbnails).map(function (key) {
			return thumbnails[key];
		});
		thumbnailArray.sort((a, b) => {
			return b.width - a.width;
		});
		const thumbnail = thumbnailArray[0];
		const stats = this.props.data.statistics;

		let details;
		let duration;
		try {
			details = this.props.data.stats.contentDetails;
			duration = moment.duration(details.duration);
		} catch (e) { }

		return (
			<div className="max-w-full w-64 m-2 p-2 bg-gray-600 rounded-lg flex">
				<a onClick={(e) => {
					e.preventDefault();
					console.log(e)
					window.dispatchEvent(new CustomEvent("popupvideo", { detail: this.props.data }));
				}} href={`https://www.youtube.com/watch?v=${this.props.data.id.videoId}`} target="_blank" rel="noreferrer" className="flex flex-col justify-between">
					<div>
						<div className="relative">
							<img alt="" src={thumbnail.url} width={thumbnail.width} height={thumbnail.width} className="rounded" />
							{duration ? <div className="absolute bottom-2 left-2 bg-black opacity-70 py-1 px-2 rounded leading-none">
								{moment(this.props.data.snippet.publishedAt).fromNow()}
							</div> : null}
							{duration ? <div className="absolute bottom-2 right-2 bg-black opacity-70 py-1 px-2 rounded leading-none">
								{tHelper(duration.minutes())}:{tHelper(duration.seconds())}
							</div> : null}
						</div>
						<div className="font-bold">{this.props.data.snippet.title}</div>
					</div>
					<div>
						<div className="text-sm">{this.props.data.snippet.channelTitle}</div>
						{stats ? <div>
							{stats.commentCount ? <Stat icon="chat" value={stats.commentCount} /> : <></>}
							{stats.likeCount ? <Stat icon="thumb_up" value={stats.likeCount} /> : <></>}
							{stats.viewCount ? <Stat icon="visibility" value={stats.viewCount} /> : <></>}
						</div> : <></>}
					</div>
				</a>
			</div>
		);
	}
}

export default Thumbnail;