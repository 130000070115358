import React from "react";
import { Link } from "react-router-dom";

export default function Navigation() {
	return (<div className="fixed top-0 left-0 bottom-0 w-32 p-2 bg-black">
		<div>
			<Link to="/">
				<span className="material-icons text-sm mr-1">local_fire_department</span>
				Trending
			</Link>
		</div>
		<div>
			<Link to="/shorts">
				<span className="material-icons text-sm mr-1">tag</span>
				Shorts
			</Link>
		</div>
	</div>);
}