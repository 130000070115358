import React from "react";
import Thumbnail from "../components/Thumbnail";
import { css } from '@emotion/react'

const HistoryElement = (props) => {
	return (<div css={css`
		position: relative;
		&:hover > div.hidden {
			display: block;
		}
		&.active {
			& > span {
				box-shadow: 0px 0px 0px 0.25rem rgba(255,255,255,0.5);
			}
		}
	`} {...props}>
		<span className="p-2 block bg-white rounded-full m-2 cursor-pointer">
			<span className="p-1 block rounded-full bg-black" />
		</span>
		<div className="hidden absolute top-9 bg-black p-1 text-center text-sm w-44 -left-12 rounded-md z-10 pointer-events-none">
			{new Date(props.data.date).toLocaleString()}
		</div>
	</div>);
}

class Feed extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			activeFeed: 'latest',
			topics: [],
		}
	}

	async fetchFeed() {
		const feed = '/api/query/' + this.props.feed;
		const data = await fetch(`${window.API_URL}${feed}`);
		const json = await data.json();
		return json;
	}
	async fetchHistory() {
		const feed = '/api/history/' + this.props.feed;
		const data = await fetch(`${window.API_URL}${feed}`);
		const json = await data.json();
		return json;
	}

	async updateFeed() {
		this.setState({
			activeFeed: 'latest-loaded',
			feed: await this.fetchFeed(),
			history: await this.fetchHistory(),
		});
	}

	componentDidMount() {
		this.updateFeed();
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.activeFeed !== prevState.activeFeed) {
			if (this.state.feed) {

				const categories = [];
				const catMap = {};
				for (let i = 0; i < this.state.feed.result.length; i++) {
					const item = this.state.feed.result[i];
					try {
						const catArr = item.stats.topicDetails.topicCategories;
						for (let o = 0; o < catArr.length; o++) {
							const element = catArr[o];
							if (!catMap[element]) {
								catMap[element] = 1;
							} else {
								catMap[element]++;
							}
						}
					} catch (e) { }
				}

				for (const key in catMap) {
					if (Object.hasOwnProperty.call(catMap, key)) {
						categories.push({
							name: key,
							count: catMap[key],
							checked: true,
						});
					}
				}
				categories.sort((a, b) => b.count - a.count);

				this.setState({
					categories: categories,
				});
			}
		}
	}

	render() {

		const categories = [];
		const catMap = {};
		if (this.state.categories) {
			for (let i = 0; i < this.state.categories.length; i++) {
				const element = this.state.categories[i];
				const splicedName = element.name.split('/');
				categories.push(<div className="mx-2 inline-block cursor-pointer" onClick={() => {
					const newCategories = this.state.categories;
					newCategories[i].checked = !newCategories[i].checked;
					this.setState({
						categories: newCategories,
					});
				}} key={i}>
					<span className="text-sm">{element.checked ? '🔴' : '⭕'}</span>
					<span>{splicedName[splicedName.length - 1]}</span>
				</div>);
				catMap[element.name] = element.checked;
			}
		}

		const items = [];
		if (this.state.feed) {
			for (let i = 0; i < this.state.feed.result.length; i++) {
				const item = this.state.feed.result[i];
				const element = <Thumbnail
					key={i}
					data={item}
				/>;
				try {
					const catArr = item.stats.topicDetails.topicCategories;
					for (let o = 0; o < catArr.length; o++) {
						if (catMap[catArr[o]]) {
							items.push(element);
							break;
						}
					}
				} catch (e) {
					items.push(element);
				}
			}
		}

		const history = [];
		if (this.state.history) {
			for (let i = this.state.history.length - 1; i >= 0; i--) {
				history.push(<HistoryElement onClick={() => {
					this.setState({
						feed: this.state.history[i],
						activeFeed: this.state.history[i].date,
					});
				}} key={i} data={this.state.history[i]} className={
					i === 0 && this.state.activeFeed.match(/^latest/) ? "active" : " "
						+
						(this.state.history[i].date === this.state.activeFeed ? "active" : " ")
				} />);
			}
		}

		return (<div>
			{this.state.feed && this.state.feed.query ? <p className="text-center">
				{this.state.feed.query.description}
			</p> : null}
			<div className="flex flex-wrap justify-center">{history}</div>
			<div>
				{categories}
			</div>
			<div className="flex flex-wrap justify-center">
				{items}
			</div>
		</div>);
	}
}

export default Feed;