import Scene from './3D';

import Navigation from './components/Navigation';
import { Routes, Route } from "react-router-dom";
import Trending from './views/trending';
import Shorts from './views/shorts';
import VideoPopup from './components/VideoPopup';

function App() {
	return (
		<div className="App">
			<Scene />
			<div className="relative ml-32 z-10">
				<Navigation />
				<p className="text-center text-xl my-4">
					YouTube trends analysis tool
				</p>
				<Routes>
					<Route path="/" element={<Trending />} />
					<Route path="/shorts" element={<Shorts />} />
				</Routes>
				<VideoPopup />
			</div>
		</div>
	);
}

export default App;
