import * as THREE from 'three';
import React from 'react';

class Scene extends React.Component {
	constructor(props) {
		super(props);
		this.canvasRef = React.createRef();
	}

	initCanvas(e) {
		if (this.canvas) {
			return;
		}
		this.canvas = e;
		this.scene = new THREE.Scene();
		this.scene.background = new THREE.Color(0x282c34);
		this.renderer = new THREE.WebGLRenderer({
			antialias: true,
			canvas: this.canvas,
		});
		this.camera = new THREE.PerspectiveCamera(75, 1, 0.1, 1000);
		this.camera.position.z = 5;

		window.addEventListener('resize', this.resize.bind(this));

		window.requestAnimationFrame(() => {
			this.resize();
			this.draw();
		});
	}

	resize() {
		const width = this.canvas.clientWidth;
		const height = this.canvas.clientHeight;

		this.renderer.setSize(width, height);
		this.camera.aspect = width / height;
		this.camera.updateProjectionMatrix();
	}

	draw() {
		this.renderer.render(this.scene, this.camera);

		requestAnimationFrame(this.draw.bind(this));
	}

	render() {
		return (<canvas className="fixed inset-0 w-full h-full max-w-full max-h-full min-w-full min-h-full" ref={this.initCanvas.bind(this)} />);
	}
}

export default Scene;